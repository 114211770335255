@tailwind base;
@tailwind components;
@tailwind utilities;

@import './base.css';
@import '~react-toastify/dist/ReactToastify.css';

/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
    url('../fonts/source-sans-pro-v13-latin-regular.woff2') format('woff2'),
    url('../fonts/source-sans-pro-v13-latin-regular.woff') format('woff');
}
/* source-sans-pro-600 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
    url('../fonts/source-sans-pro-v13-latin-600.woff2') format('woff2'),
    url('../fonts/source-sans-pro-v13-latin-600.woff') format('woff');
}

/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato Regular'), local('Lato-Regular'),
    url('../fonts/lato-v16-latin-regular.woff2') format('woff2'),
    url('../fonts/lato-v16-latin-regular.woff') format('woff');
}

/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local('Lato Bold'), local('Lato-Bold'),
    url('../fonts/lato-v16-latin-700.woff2') format('woff2'),
    url('../fonts/lato-v16-latin-700.woff') format('woff');
}

*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #ebebeb;
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  background-color: #fafafa;
  font-family: Lato, Arial, Helvetica, sans-serif;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  -webkit-appearance: none;
}

input::placeholder,
textarea::placeholder {
  color: #8a8a8a;
}

input[type='number'] {
  -moz-appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay--after-open {
  opacity: 1 !important;
  margin-top: 0 !important;
}

.ReactModal__Overlay--before-close {
  opacity: 0 !important;
}

[data-whatintent='mouse'] :focus:not(button):focus:not(a),
[data-whatintent='touch'] :focus:not(button):focus:not(a),
[data-whatintent='keyboard'] :focus:not(button):focus:not(a) {
  border-color: rgba(0, 0, 0, 0.28);
  box-shadow: 0 0 8px rgba(56, 118, 171, 0.25);
  outline: none;
}

[data-whatintent='mouse'] button:focus,
[data-whatintent='mouse'] a:focus {
  outline: none;
}
